import { Link, useLocation, useMatches } from "@remix-run/react";
import { cva } from "class-variance-authority";
import { cn } from "~/lib/utils";
export const Breadcrumb = () => {
  const matches = useMatches();

  return (
    <ol className="flex items-center gap-2">
      {matches
        // @ts-ignore
        .filter((match) => match.handle?.breadcrumb)
        .map((match, index) => {
          const separator =
            index === 0 ? null : (
              <svg
                width="7"
                height="13"
                viewBox="0 0 7 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 11.5L5 6.5L1 1.5"
                  stroke="#161615"
                  strokeOpacity="0.6"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            );
          return (
            <li
              key={match.id}
              className={
                "flex items-center gap-2 whitespace-nowrap last:overflow-hidden last:text-ellipsis"
              }
            >
              {separator && <span>{separator}</span>}
              <span className="last:truncate">
                {/* @ts-ignore */}
                {match.handle.breadcrumb(match)}
              </span>
            </li>
          );
        })}
    </ol>
  );
};

export const breadcrumbVariants = cva(
  "font-[300] text-[14px] text-[#100F0F] leading-[25.2px]",
  {
    variants: {
      variant: {
        // トップページ
        top: "font-poppins",
        // 子ページ
        child: "",
      },
    },
    defaultVariants: {
      variant: "top",
    },
  },
);

export const BreadcrumbItem = ({
  children,
  variant,
  to,
}: {
  children: React.ReactNode;
  variant: "top" | "child";
  to?: string;
}) => {
  const location = useLocation();

  if (to && location.pathname !== to) {
    return (
      <Link
        to={to}
        className={cn(
          breadcrumbVariants({ variant: variant }),
          "text-gray-deep",
        )}
      >
        {children}
      </Link>
    );
  }

  return (
    <span className={cn(breadcrumbVariants({ variant: variant }))}>
      {children}
    </span>
  );
};
